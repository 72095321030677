export default {
  "pages": {
    "home": {
      "meta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Paint — draw pictures, figures & more"])}
      },
      "tooltip": {
        "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Move tool"])},
        "zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zoom tool"])},
        "pen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pen tool"])},
        "marker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marker tool"])},
        "eraser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eraser tool"])},
        "magic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Erase all"])},
        "thickness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Brush Thickness"])},
        "opacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Color Opacity"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pick a Color"])}
      },
      "cap": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["H<i>e</i>y!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Try a&nbsp;desktop version to&nbsp;see the full functionality of&nbsp;WebPaint."])}
      },
      "workButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s work"])}
    },
    "404": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["The page does not exist."])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Back Home"])}
    }
  },
  "navbar": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save File"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Share"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["About"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Help"])},
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Support us"])},
    "tooltip": {
      "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Undo"])},
      "redo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Redo"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Save File"])},
      "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Theme"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Language"])}
    }
  },
  "popup": {
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Paint"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Minimalistic graphics editor in&nbsp;the browser. <br><br> Idea by&nbsp;Greene <i>&</i>&nbsp;Lapp studio.<br> Designed by&nbsp;<a href='https://t.me/greeneboy' target='_blank'>Alexander Greene</a>, developed by&nbsp;<a href='https://t.me/seamminer' target='_blank'>Platon Lapp</a> in&nbsp;2022."])}
    }
  }
}