import { vModelText as _vModelText, createElementVNode as _createElementVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, normalizeStyle as _normalizeStyle, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-e9e1731e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "pickedColorTool" }
const _hoisted_2 = ["onClick"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", {
      class: _normalizeClass(["color", {
        active:
          _ctx.activeColor == _ctx.hex2rgb(_ctx.colorPickerColor) &&
          !_ctx.colors.includes(_ctx.hex2rgb(_ctx.colorPickerColor)),
      }]),
      onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.colorPicker.click()))
    }, [
      _withDirectives(_createElementVNode("input", {
        ref: "colorPicker",
        type: "color",
        id: "colorPicker",
        class: "color",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.colorPickerColor) = $event)),
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.setActiveColor(_ctx.hex2rgb(_ctx.colorPickerColor))))
      }, null, 512), [
        [_vModelText, _ctx.colorPickerColor]
      ])
    ], 2),
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.colors, (color) => {
      return (_openBlock(), _createElementBlock("div", {
        key: color,
        class: _normalizeClass(["color", { active: _ctx.activeColor == color }]),
        style: _normalizeStyle(`background-color: rgb(${color});`),
        onClick: ($event: any) => (_ctx.setActiveColor(color))
      }, null, 14, _hoisted_2))
    }), 128))
  ]))
}