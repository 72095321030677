
import { defineComponent, ref, watchEffect, computed } from "vue";
import { useStore } from "vuex";
import { useHotkey } from "vue-use-hotkey";

export default defineComponent({
  setup() {
    const store = useStore();

    const paint = computed(() => store.state.paint.paint);
    const lineWidth_ = computed(() => paint.value._lineWidth);

    return {
      lineWidth_,
      paint
    };
  },
});
