
import { defineComponent, defineAsyncComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useStore } from "vuex";

export default defineComponent({
  components: {
    Navbar: defineAsyncComponent(() => import("./components/Navbar.vue")),
  },
  setup() {
    const { locale } = useI18n();
    const store = useStore();
    store.dispatch("theme/init");

    const lang =
      localStorage.getItem("webpaint:selectedLanguage") || navigator.language;
    if (/^[a-z][a-z]-[A-Z][A-Z]/.test(lang)) {
      locale.value = lang;
    }
  },
});
