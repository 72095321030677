
import { computed, defineComponent, watchEffect, ref, Ref } from "vue";
import { useStore } from "vuex";
import { useHotkey } from "vue-use-hotkey";
import useDebouncedRef from "@/useDebouncedRef";
import { Hotkey } from "vue-use-hotkey/dist/types/types";

export default defineComponent({
  setup() {
    const store = useStore();
    //TODO: refactor getting colors
    const root = getComputedStyle(document.documentElement);
    const colors: string[] = [];
    const shortcuts: Hotkey[] = [
      {
        keys: ["c", "6"],
        handler() {
          colorPicker.value?.click();
          setActiveColor(hex2rgb(colorPickerColor.value));
        },
      },
      {
        keys: ["с", "6"],
        handler() {
          colorPicker.value?.click();
          setActiveColor(hex2rgb(colorPickerColor.value));
        },
      },
    ];
    ["red", "orange", "grey", "blue", "black"].forEach((color, index) => {
      color = root.getPropertyValue(`--${color}`);
      colors.push(color);
      shortcuts.push({
        keys: ["c", `${5 - index}`],
        handler() {
          setActiveColor(color);
        },
      });
      shortcuts.push({
        keys: ["с", `${5 - index}`],
        handler() {
          setActiveColor(color);
        },
      });
    });

    const colorPicker: Ref<HTMLInputElement | null> = ref(null);
    const activeColor = computed(() => store.state.theme.activeColor);
    const setActiveColor = (color: string) => {
      store.dispatch("theme/updateActiveColor", color);
    };

    const hex2rgb = (hex: string) => {
      if (/^#([0-9a-f]{3}|[0-9a-f]{6})$/i.test(hex)) {
        return (hex = hex.replace("#", ""))
          .match(new RegExp("(.{" + hex.length / 3 + "})", "g"))!
          .map(function (l: string) {
            return parseInt(hex.length % 2 ? l + l : l, 16);
          })
          .join(" ");
      } else {
        return "0 0 0";
      }
    };

    const colorPickerColor = useDebouncedRef(
      store.state.theme.activeColor,
      500
    );

    watchEffect(() => setActiveColor(hex2rgb(colorPickerColor.value)));

    useHotkey(shortcuts);

    return {
      colors,
      activeColor,
      setActiveColor,
      colorPickerColor,
      hex2rgb,
      colorPicker,
    };
  },
});
