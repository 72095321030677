
import {
  defineComponent,
  onMounted,
  ref,
  Ref,
  onUnmounted,
  computed,
} from "vue";

import { Paint } from "../paint/main";

import Dock from "@/components/Dock.vue";
// import Button from "@/components/Button.vue";
import { useStore } from "vuex";
import { useI18n } from "vue-i18n";
import { useHotkey } from "vue-use-hotkey";
import shortcuts from "@/paint/shortcuts";

export default defineComponent({
  components: {
    Dock,
  },
  name: "Home",

  setup() {
    const { t } = useI18n();

    const store = useStore();

    const canvas: Ref<HTMLCanvasElement | null> = ref(null);
    const paint = computed(() => store.state.paint.paint);

    onMounted(() => {
      store.commit("paint/change", new Paint(canvas.value!));
      paint.value.init();
    });

    shortcuts(store);

    return {
      canvas,
      t,
      paint,
    };
  },
});
