
import { defineComponent } from "vue";

enum Sizes {
  Small = "small",
  Big = "big",
}

enum Types {
  Accent = "accent",
  White = "white",
}

enum SlotTypes {
  Text = "text",
  Icon = "icon",
}

export default defineComponent({
  props: {
    size: {
      type: String as () => Sizes,
      default: Sizes.Small,
    },
    type: {
      type: String as () => Types,
      default: Types.White,
    },
    slotType: {
      type: String as () => SlotTypes,
      default: SlotTypes.Text,
    },
  },
});
