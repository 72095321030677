
import { defineComponent, ref, watchEffect, computed } from "vue";
import useDebouncedRef from "@/useDebouncedRef";
import { useStore } from "vuex";

export default defineComponent({
  setup() {
    const store = useStore();

    const paint = computed(() => store.state.paint.paint);
    const opacity_ = useDebouncedRef(255, 250);

    watchEffect(() => {
      paint.value._opacity = parseInt(opacity_.value);
    });

    return {
      opacity_,
    };
  },
});
