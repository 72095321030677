<template>
  <svg
    width="103"
    height="179"
    viewBox="0 0 103 179"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g filter="url(#filter0_d_581_5642)">
      <path
        d="M31.5 41.7226C31.5 34.2457 31.5 30.5072 33.1077 27.7226C34.1609 25.8984 35.6758 24.3835 37.5 23.3303C40.2846 21.7226 44.0231 21.7226 51.5 21.7226V21.7226C58.9769 21.7226 62.7154 21.7226 65.5 23.3303C67.3242 24.3835 68.8391 25.8984 69.8923 27.7226C71.5 30.5072 71.5 34.2457 71.5 41.7226V55.7226H31.5V41.7226Z"
        fill="url(#paint0_radial_581_5642)"
      />
    </g>
    <path d="M31.448 55.7226H71.448V157.723H31.448V55.7226Z" fill="#1F1F1F" />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M70.254 116.138C57.9208 117.679 45.4913 117.679 33.1582 116.138L33.2285 115.53C45.5149 117.065 57.8972 117.065 70.1837 115.53L70.254 116.138Z"
      fill="white"
      fill-opacity="0.1"
    />
    <g opacity="0.2" filter="url(#filter1_f_581_5642)">
      <path
        d="M53.0967 21.9822C53.0997 21.838 53.2174 21.7226 53.3617 21.7226V21.7226C53.505 21.7226 53.6224 21.8365 53.6266 21.9798L57.4754 152.307C57.5341 154.296 55.9375 155.94 53.9474 155.94V155.94C51.9698 155.94 50.3781 154.315 50.4187 152.338L53.0967 21.9822Z"
        fill="white"
      />
    </g>
    <g filter="url(#filter2_d_581_5642)">
      <path
        d="M70.3924 18.6153L72.4142 33.9296L85.9391 41.3925L70.6247 43.4143L63.1618 56.9392L61.14 41.6248L47.6152 34.1619L62.9295 32.1401L70.3924 18.6153Z"
        fill="#9746FF"
      />
      <path
        d="M70.3924 18.6153L72.4142 33.9296L85.9391 41.3925L70.6247 43.4143L63.1618 56.9392L61.14 41.6248L47.6152 34.1619L62.9295 32.1401L70.3924 18.6153Z"
        stroke="white"
        stroke-width="2"
      />
    </g>
    <g filter="url(#filter3_d_581_5642)">
      <path
        d="M28.3298 44.3066L33.7541 51.6256L42.864 51.6045L35.545 57.0287L35.5661 66.1386L30.1418 58.8196L21.032 58.8407L28.3509 53.4165L28.3298 44.3066Z"
        fill="#9746FF"
      />
      <path
        d="M28.3298 44.3066L33.7541 51.6256L42.864 51.6045L35.545 57.0287L35.5661 66.1386L30.1418 58.8196L21.032 58.8407L28.3509 53.4165L28.3298 44.3066Z"
        stroke="white"
        stroke-width="2"
      />
    </g>
    <g filter="url(#filter4_d_581_5642)">
      <path
        d="M67.874 62.1615L70.095 72.0603L79.3797 76.1484L69.481 78.3694L65.3929 87.6541L63.1718 77.7554L53.8871 73.6673L63.7859 71.4463L67.874 62.1615Z"
        fill="#9746FF"
      />
      <path
        d="M67.874 62.1615L70.095 72.0603L79.3797 76.1484L69.481 78.3694L65.3929 87.6541L63.1718 77.7554L53.8871 73.6673L63.7859 71.4463L67.874 62.1615Z"
        stroke="white"
        stroke-width="2"
      />
    </g>
    <defs>
      <filter
        id="filter0_d_581_5642"
        x="30.5"
        y="20.7226"
        width="42"
        height="36"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset />
        <feGaussianBlur stdDeviation="0.5" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_581_5642"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_581_5642"
          result="shape"
        />
      </filter>
      <filter
        id="filter1_f_581_5642"
        x="33.4267"
        y="4.73129"
        width="41.0417"
        height="168.2"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="BackgroundImageFix"
          result="shape"
        />
        <feGaussianBlur
          stdDeviation="8.49566"
          result="effect1_foregroundBlur_581_5642"
        />
      </filter>
      <filter
        id="filter2_d_581_5642"
        x="40.47"
        y="12.47"
        width="52.6145"
        height="52.6145"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.592157 0 0 0 0 0.27451 0 0 0 0 1 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_581_5642"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_581_5642"
          result="shape"
        />
      </filter>
      <filter
        id="filter3_d_581_5642"
        x="13.9937"
        y="38.2683"
        width="35.9087"
        height="35.9086"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.592157 0 0 0 0 0.27451 0 0 0 0 1 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_581_5642"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_581_5642"
          result="shape"
        />
      </filter>
      <filter
        id="filter4_d_581_5642"
        x="46.7014"
        y="55.9758"
        width="39.864"
        height="39.8641"
        filterUnits="userSpaceOnUse"
        color-interpolation-filters="sRGB"
      >
        <feFlood flood-opacity="0" result="BackgroundImageFix" />
        <feColorMatrix
          in="SourceAlpha"
          type="matrix"
          values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
          result="hardAlpha"
        />
        <feOffset dy="1" />
        <feGaussianBlur stdDeviation="2" />
        <feComposite in2="hardAlpha" operator="out" />
        <feColorMatrix
          type="matrix"
          values="0 0 0 0 0.592157 0 0 0 0 0.27451 0 0 0 0 1 0 0 0 0.1 0"
        />
        <feBlend
          mode="normal"
          in2="BackgroundImageFix"
          result="effect1_dropShadow_581_5642"
        />
        <feBlend
          mode="normal"
          in="SourceGraphic"
          in2="effect1_dropShadow_581_5642"
          result="shape"
        />
      </filter>
      <radialGradient
        id="paint0_radial_581_5642"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(34.5 55.7226) rotate(-40.6013) scale(46.0977 49.6086)"
      >
        <stop stop-color="white" />
        <stop offset="1" stop-color="white" />
      </radialGradient>
    </defs>
  </svg>
</template>
