import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, resolveDirective as _resolveDirective, withCtx as _withCtx, createVNode as _createVNode, withDirectives as _withDirectives, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0ce46dd6"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "dock" }
const _hoisted_2 = { class: "controlPanel" }
const _hoisted_3 = ["src"]
const _hoisted_4 = ["src"]
const _hoisted_5 = { class: "drawTools" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Button = _resolveComponent("Button")!
  const _component_Pen = _resolveComponent("Pen")!
  const _component_Marker = _resolveComponent("Marker")!
  const _component_Erase = _resolveComponent("Erase")!
  const _component_Magic = _resolveComponent("Magic")!
  const _component_BrushThickness = _resolveComponent("BrushThickness")!
  const _component_OpacityTool = _resolveComponent("OpacityTool")!
  const _component_PickedColorTool = _resolveComponent("PickedColorTool")!
  const _directive_tooltip = _resolveDirective("tooltip")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _withDirectives(_createVNode(_component_Button, {
        class: "move",
        "data-size": "small",
        "data-type": _ctx.cursor === 'move' ? 'accent' : 'white',
        "data-slot": "icon",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.paint.moveTool()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/Arrow.svg'),
            alt: "Arrow"
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }, 8, ["data-type"]), [
        [
          _directive_tooltip,
          {
          content: `${_ctx.t('pages.home.tooltip.move')} <span>V</span>`,
          html: true,
        },
          void 0,
          { "top-center": true }
        ]
      ]),
      _withDirectives(_createVNode(_component_Button, {
        class: "scale",
        "data-size": "small",
        "data-type": _ctx.cursor === 'magnifier' ? 'accent' : 'white',
        "data-slot": "icon",
        onClick: _cache[1] || (_cache[1] = ($event: any) => (_ctx.paint.magnifier()))
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: require('@/assets/Magnifier.svg'),
            alt: "Scale"
          }, null, 8, _hoisted_4)
        ]),
        _: 1
      }, 8, ["data-type"]), [
        [
          _directive_tooltip,
          {
          content: `${_ctx.t('pages.home.tooltip.zoom')} <span>M</span>`,
          html: true,
        },
          void 0,
          { "top-center": true }
        ]
      ])
    ]),
    _createElementVNode("div", _hoisted_5, [
      _withDirectives(_createVNode(_component_Pen, {
        onClick: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectDrawTool('round'))),
        class: _normalizeClass({ active: _ctx.cursor == 'pen' && _ctx.lineCap_ === 'round' })
      }, null, 8, ["class"]), [
        [
          _directive_tooltip,
          {
          content: `${_ctx.t('pages.home.tooltip.pen')} <span>P</span>`,
          html: true,
        },
          void 0,
          { "top-center": true }
        ]
      ]),
      _withDirectives(_createVNode(_component_Marker, {
        onClick: _cache[3] || (_cache[3] = ($event: any) => (_ctx.selectDrawTool('square'))),
        class: _normalizeClass({ active: _ctx.cursor == 'pen' && _ctx.lineCap_ === 'square' })
      }, null, 8, ["class"]), [
        [
          _directive_tooltip,
          {
          content: `${_ctx.t('pages.home.tooltip.marker')} <span>Shift + P</span>`,
          html: true,
        },
          void 0,
          { "top-center": true }
        ]
      ]),
      _withDirectives(_createVNode(_component_Erase, {
        onClick: _cache[4] || (_cache[4] = ($event: any) => (_ctx.selectDrawTool())),
        class: _normalizeClass({ active: _ctx.cursor == 'eraser' })
      }, null, 8, ["class"]), [
        [
          _directive_tooltip,
          {
          content: `${_ctx.t('pages.home.tooltip.eraser')} <span>E</span>`,
          html: true,
        },
          void 0,
          { "top-center": true }
        ]
      ]),
      _withDirectives(_createVNode(_component_Magic, {
        onClick: _cache[5] || (_cache[5] = ($event: any) => (_ctx.paint.clear()))
      }, null, 512), [
        [
          _directive_tooltip,
          {
          content: `${_ctx.t('pages.home.tooltip.magic')} <span>Shift + E</span>`,
          html: true,
        },
          void 0,
          { "top-center": true }
        ]
      ])
    ]),
    _withDirectives(_createVNode(_component_BrushThickness, null, null, 512), [
      [
        _directive_tooltip,
        {
        content: `${_ctx.t('pages.home.tooltip.thickness')} <span>T + [1-5]</span>`,
        html: true,
      },
        void 0,
        { "top-center": true }
      ]
    ]),
    _withDirectives(_createVNode(_component_OpacityTool, null, null, 512), [
      [
        _directive_tooltip,
        _ctx.t('pages.home.tooltip.opacity'),
        void 0,
        { "top-center": true }
      ]
    ]),
    _withDirectives(_createVNode(_component_PickedColorTool, null, null, 512), [
      [
        _directive_tooltip,
        {
        content: `${_ctx.t('pages.home.tooltip.color')} <span>C + [1-6]</span>`,
        html: true,
      },
        void 0,
        { "top-center": true }
      ]
    ])
  ]))
}