export default {
  "pages": {
    "home": {
      "meta": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Paint — рисуй и твори"])}
      },
      "tooltip": {
        "move": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Перемещать"])},
        "zoom": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Масштабировать"])},
        "pen": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ручка"])},
        "marker": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Маркер"])},
        "eraser": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ластик"])},
        "magic": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Стереть всё"])},
        "thickness": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жирность кисти"])},
        "opacity": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прозрачность"])},
        "color": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Выбрать цвет"])}
      },
      "cap": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Х<i>э</i>й!"])},
        "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Попробуй компьютерную версию Web Paint, чтобы воспользоваться всеми инструментами."])}
      },
      "workButton": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Let’s work"])}
    },
    "404": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cтраницы не&nbsp;существует."])},
      "link": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["На Главную"])}
    }
  },
  "navbar": {
    "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить файл"])},
    "share": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поделиться"])},
    "about": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["О нас"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Помощь"])},
    "donate": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поддержать нас"])},
    "tooltip": {
      "undo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Отменить"])},
      "redo": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Вернуть"])},
      "save": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сохранить"])},
      "theme": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Поменять тему"])},
      "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сменить язык"])}
    }
  },
  "popup": {
    "about": {
      "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Web Paint"])},
      "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Минималистичный граф.редактор в&nbsp;браузере. <br><br> Идея студии Greene <i>&amp;</i>&nbsp;Lapp.<br> Задизайнено <a href='https://t.me/greeneboy' target='_blank'>Александром Грином</a>, разработано <a href='https://t.me/seamminer' target='_blank'>Лапп Платоном</a> в&nbsp;2022."])}
    }
  }
}