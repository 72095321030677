
import { computed, defineComponent, ref, watchEffect } from "vue";
import { useHotkey } from "vue-use-hotkey";
import { useStore } from "vuex";

import Button from "@/components/Button.vue";

import BrushThickness from "@/components/Dock/BrushThickness.vue";
import OpacityTool from "@/components/Dock/OpacityTool.vue";
import PickedColorTool from "@/components/Dock/PickedColorTool.vue";

import Erase from "@/components/Dock/DrawTools/Erase.vue";
import Magic from "@/components/Dock/DrawTools/Magic.vue";
import Marker from "@/components/Dock/DrawTools/Marker.vue";
import Pen from "@/components/Dock/DrawTools/Pen.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    Button,

    BrushThickness,
    OpacityTool,
    PickedColorTool,

    Erase,
    Magic,
    Marker,
    Pen,
  },

  setup() {
    const { t } = useI18n();
    const store = useStore();

    const paint = computed(() => store.state.paint.paint);
    const lineCap_ = computed(() => paint.value._lineCap);
    const cursor = computed(() => paint.value._cursor);

    const selectDrawTool = (lineCap = "") => {
      if (lineCap) {
        paint.value._lineCap = lineCap;
        paint.value.drawTool();
      } else {
        paint.value.eraserTool();
      }
    };

    watchEffect(() => {
      paint.value._lineCap = lineCap_.value;
    });

    return {
      lineCap_,
      cursor,
      paint,
      t,
      selectDrawTool,
    };
  },
});
